import React, { FC, useState } from 'react';
import ComponentTemplate from '../../Task';

const TaskReady: FC = () => {
  const [value, setValue] = useState(-1);

  const handleChange = (item: boolean | string) => {
    let res: number = value;

    if (item === true || item === '1') res = 1;
    else res = -1;

    setValue(res);
  };

  return (
    <div>
      <input
        type="checkbox"
        checked={value === 1}
        onChange={(event) => handleChange(event.target.checked)}
      />
      <select value={value} onChange={(event) => handleChange(event.target.value)}>
        <option value={-1}>не отмечено</option>
        <option value={1}>отмечено</option>
      </select>
    </div>
  );
};

const Task9: FC = () => {
  const taskTitle: string =
    "9. Дан селект с двумя значениями: 'отмечено' и 'не отмечено'. Дан также чекбокс. Сделай так, чтобы при изменении значения селекта, чекбокс менял свое состояние с 'отмечено' на 'не отмечено' и наоброт.";
  const taskClassSolution: string = `
  class App extends React.Component {
    constructor() {
      super();
      this.state = {
        flag: false,
      };
    }

    render() {
      return (
        <div>
          <input
            type='checkbox'
            checked={this.state.flag}
            onChange={() => this.setState({ flag: !this.state.flag })}
          />
          <select
            value={this.state.flag}
            onChange={() => this.setState({ flag: !this.state.flag })}
          >
            <option value={false}>не отмечено</option>
            <option value={true}>отмечено</option>
          </select>
        </div>
      );
    }
  }
  `;
  const taskFuncSolution: string = `
  const App = () => {
    const [value, setValue] = useState(-1);

    const handleChange = (item) => {
      let res: number = value;

      if (item === true || item === '1') res = 1;
      else res = -1;

      setValue(res);
    };

    return (
      <div>
        <input
          type="checkbox"
          checked={value === 1}
          onChange={(event) => handleChange(event.target.checked)}
        />
        <select value={value} onChange={(event) => handleChange(event.target.value)}>
          <option value={-1}>не отмечено</option>
          <option value={1}>отмечено</option>
        </select>
      </div>
    );
  };
  `;

  return (
    <div>
      <ComponentTemplate
        taskTitle={taskTitle}
        taskClassSolution={taskClassSolution}
        taskFuncSolution={taskFuncSolution}
        TaskReady={TaskReady}
      />
    </div>
  );
};

export default Task9;
